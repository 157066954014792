<template>
  <div class="product-alternative__dialog px-5 py-4 d-flex flex-column">
    <div v-if="product" class="mb-2 d-flex flex-column">
      <h3 class="mb-2">Prodotto</h3>
      <v-card outlined class="pa-2" style="max-width: none;">
        <div class="product-container__flex">
          <div class="product__left-group">
            <v-img
              :src="product.mediaURL"
              class="mx-auto"
              width="100px"
              height="100px"
              max-width="100px"
              max-height="100px"
            />
            <div class="d-flex flex-column">
              <div class="product-name">
                {{ product.name }}
              </div>
              <div class="product-descr" v-if="product.description">
                <span>{{ product.description }}</span>
              </div>
              <span>{{ product.shortDescr }}</span>
            </div>
          </div>
          <div class="product-price__wrapper">
            <ProductPrice :vertical="true" :product="product" />
          </div>
        </div>
      </v-card>
    </div>

    <div class="d-flex flex-column mt-2" v-if="products?.length > 0">
      <h3>Solitamente sostituito con:</h3>
      <swiper
        :key="swiperKey"
        class="carousel w-100 my-2 py-3"
        :options="swiperOption"
        ref="swiperRef"
      >
        <swiper-slide
          class="fill-height"
          v-for="product in products"
          :key="product.productId"
        >
          <v-card outlined class="product-alternative fill-height pa-2">
            <div class="d-flex flex-column">
              <v-img
                :src="product.mediaURL"
                class="mx-auto"
                width="100px"
                height="100px"
                max-width="100px"
                max-height="100px"
              />
              <template>
                <div class="product-name">{{ product.name }}</div>
                <div class="product-descr" v-if="product.description">
                  <span>{{ product.description }}</span>
                </div>
                <span>{{ product.shortDescr }}</span>
              </template>
              <div class="my-3">
                <ProductPrice :product="product" />
              </div>
            </div>
          </v-card>
        </swiper-slide>
      </swiper>
    </div>
    <swiper-paginator
      :showBullets="true"
      :paginationClass="paginationClass"
      :length="products?.length"
    />
  </div>
</template>
<style scoped lang="scss">
.product-alternative__dialog {
  zoom: 95%;
  .swiper-containrt {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .carousel {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
}

.v-card {
  // max-width: fit-content;
}

.swiper-slide .v-card {
  //  width: 100%;
  // max-width: 220px;
}
.swiper-slide {
  max-width: 200px !important;
}
.swiper-slide {
  min-width: 200px !important;
}

.theme--light .v-sheet--outlined {
  border-color: rgba(0, 0, 0, 0.2);
}

.product-name {
  min-height: 45px;
  white-space: normal;
  font-weight: 700;
}

.product-descr {
  span {
    font-weight: 400;
    font-size: 10px;
    color: $text-color;
    border: 1px solid var(--v-grey-darken1);
    border-radius: $border-radius-root;
    white-space: nowrap;
    overflow: hidden;
    padding: 1px 5px;
  }
}

.product-alternative {
  border-color: var(--v-primary-lighten1) !important;
}

.product-container__flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product__left-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
}

.product-price__wrapper {
  align-self: flex-end;
}

@media screen and (max-width: 768px) {
  .product-container__flex {
    flex-direction: column;
  }

  .product__left-group {
    flex-direction: column;
  }

  .product-price__wrapper {
    align-self: auto !important;
    margin-block: 12px;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";
//import ProductService from "@/commons/service/productService";
import eurospinCustomService from "@/service/eurospinCustomService";
import ProductPrice from "@/components/product/ProductPrice.vue";
import SwiperPaginator from "../SwiperPaginator.vue";
// import ProductListSlider from "@/components/product/ProductListSlider.vue";
export default {
  name: "ProductAlternative",
  components: { ProductPrice, SwiperPaginator },
  //   , ProductListSlider
  mixins: [productMixin],
  props: {
    product: { type: Object, required: true }
  },
  data() {
    return {
      error: null,
      swiperKey: Date.now(),
      paginationClass: "product-alternative",
      products: null,
      response: {},
      swiperOption: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 16,
        watchOverflow: true,
        clickable: true,
        pagination: {
          el: "#pagination-product-alternative",
          clickable: true
        },
        // navigation: {
        //   prevEl: `#product-list-slider-prev-product-alternative-pagination`,
        //   nextEl: `#product-list-slider-next-product-alternative-pagination`
        // },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          600: {
            slidesPerView: 3,
            slidesPerGroup: 2
          }
          // 960: {
          //   slidesPerView: 3
          // }
          // 1280: {
          //   slidesPerView: 3
          // },
          // 1590: {
          //   slidesPerView: 3
          // }
        }
      }
    };
  },
  methods: {
    // destroySwiper() {
    //   if (this.$refs.swiperRef && this.$refs.swiperRef.swiper) {
    //     this.$refs.swiperRef.swiper.destroy(true, true);
    //   }
    // },
    async getProductAlternativeDetails() {
      let res = await eurospinCustomService.getProductAlternativeDetails(
        true,
        this.product.productId
      );
      if (res?.products) {
        this.products = res.products;
      }
    }
  },
  async created() {
    await this.getProductAlternativeDetails();
  },
  beforeDestroy() {
    //  this.destroySwiper();
  },
  mounted() {
    this.swiperKey = Date.now();
    // this.$on("closed", () => {
    //   this.destroySwiper();
    // });
  }
};
</script>
