var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item pa-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-row align-center mr-auto",class:{ 'pl-1': !_vm.isCheckout },attrs:{"cols":"8","sm":_vm.full ? 9 : 6,"md":_vm.full ? 4 : 6}},[_c('img',{class:{ 'mr-3': !_vm.isCheckout },attrs:{"src":_vm.item.product.mediaURL,"alt":'Immagine prodotto'}}),_c('div',{staticClass:"description"},[_c('div',{staticClass:"name text-wrap font-weight-bold",class:{
              'order-1': _vm.item.cartItemInfo.SUBSCRIPTION_PLAN_ID
            }},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('div',{staticClass:"description",class:{
              'order-0': _vm.item.cartItemInfo.SUBSCRIPTION_PLAN_ID
            }},[_vm._v(" "+_vm._s(_vm.item.product.description)+" ")]),(
              _vm.showDescrWeight &&
                _vm.item.product.productInfos.PRODUCT_NATURE_ID != '10'
            )?_c('div',{staticClass:"short-descr"},[_vm._v(" "+_vm._s(_vm.item.product.shortDescr)+" ")]):_vm._e(),(_vm.item.product.productInfos.PRODUCT_NATURE_ID != '10')?_c('div',{staticClass:"cart-item-info"},[_c('em',[_vm._v(_vm._s(_vm.item.cartItemInfo.size))])]):_vm._e()])]),(
          (_vm.item.editable &&
            _vm.full &&
            _vm.item.product.productInfos.PRODUCT_NATURE_ID != '10') ||
            (_vm.full &&
              _vm.item.cartItemInfo.SUBSCRIPTION_PLAN_ID &&
              _vm.isCheckout &&
              _vm.$vuetify.breakpoint.smAndUp)
        )?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"3","md":"2"}},[(_vm.warehousePromoBody)?_c('ProductPromo',{staticClass:"font-weight-bold mb-3",attrs:{"warehousePromo":_vm.product.warehousePromo}}):_vm._e(),(_vm.item.editable || _vm.item.cartItemInfo.SUBSCRIPTION_PLAN_ID)?_c('ProductPrice',{attrs:{"product":_vm.item.product,"showDescrWeight":_vm.showDescrWeight}}):_vm._e()],1):_vm._e(),(_vm.item.cartItemInsertionTypeId == 2)?_c('v-col',{staticClass:"d-flex justify-sm-center order-sm-2",attrs:{"cols":"4","sm":"3","md":"3"}},[_c('span',{staticClass:"cart-item--gift text-uppercase text-center font-weight-bold mx-md-auto",domProps:{"innerHTML":_vm._s(_vm.$t('product.freeProduct'))}})]):_vm._e(),(_vm.item.cartItemInfo.REWARD)?_c('v-col',{staticClass:"d-flex justify-sm-center order-sm-2",attrs:{"cols":"4","sm":"3","md":"3"}},[_c('span',{staticClass:"cart-item--promo text-uppercase text-center font-weight-bold mx-md-auto",domProps:{"innerHTML":_vm._s(_vm.$t('product.freePromoProduct'))}})]):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"8","sm":_vm.full ? 9 : 3,"md":_vm.item.cartItemInsertionTypeId == 2 && _vm.full ? 3 : 3}},[_c('div',{staticClass:"qty-wrap rounded-pill",class:{ 'not-empty': _vm.quantity > 0 }},[(
              _vm.item.editable &&
                _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
                _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '10'
            )?_c('a',{staticClass:"minus",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.minus(true, { infos: _vm.item.cartItemInfo })},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$minus")])],1):_vm._e(),_c('div',{staticClass:"val-cnt",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])]),(
              _vm.item.editable &&
                _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
                _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '10'
            )?_c('a',{staticClass:"plus",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.plus(true, { infos: _vm.item.cartItemInfo })},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$plus")])],1):_vm._e()])]),(_vm.item.product.productInfos.PRODUCT_NATURE_ID === 'rewards')?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"8","sm":_vm.full ? 9 : 3,"md":"3"}},[_c('div',{staticClass:"qty-wrap rounded-pill",class:{ 'not-empty': _vm.quantity > 0 }},[_c('div',{staticClass:"val-cnt",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])])])]):_vm._e(),(_vm.item.editable || _vm.item.cartItemInfo.SUBSCRIPTION_PLAN_ID)?_c('v-col',{staticClass:"d-flex flex-row align-center justify-center",attrs:{"cols":"3"}},[_c('div',{staticClass:"gross-total price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")]),(_vm.item.editable)?_c('v-btn',{staticClass:"remove-cart-item ml-1 ml-md-4",attrs:{"icon":"","aria-label":"Rimuovi dal carrello"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],1):_vm._e(),(
          _vm.item.editable &&
            _vm.isCheckout &&
            _vm.productsAlternatives &&
            _vm.productsAlternatives[_vm.item.product.productId] &&
            _vm.productsAlternatives[_vm.item.product.productId].length > 0 &&
            !_vm.hideAcceptSubstitutes &&
            !_vm.item.product.warehousePromo &&
            _vm.item.product.productInfos.PRODUCT_NATURE_ID != '10'
        )?_c('v-col',{staticClass:"pt-0 d-flex flex-column flex-sm-row",attrs:{"cols":"12"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[(
            _vm.item.product.productInfos.PRODUCT_NATURE_ID !== 'rewards' &&
              _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8' &&
              _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '10'
          )?_c('v-checkbox',{staticClass:"accept-alternatives",attrs:{"dense":"","color":"primary","hide-details":""},on:{"click":function($event){!_vm.acceptAlternative ? _vm.openAlternativeProductsModal() : null}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("cart.alternatives.acceptAlternativesSingle")))])]},proxy:true}],null,false,2221846050),model:{value:(_vm.acceptAlternative),callback:function ($$v) {_vm.acceptAlternative=$$v},expression:"acceptAlternative"}}):_vm._e(),(
            _vm.productsAlternatives &&
              _vm.productsAlternatives[_vm.item.product.productId] &&
              _vm.productsAlternatives[_vm.item.product.productId].length > 0
          )?_c('v-btn',{staticClass:"ml-md-3",attrs:{"text":"","color":"primary"},on:{"click":_vm.openAlternativeProductsModal}},[_c('small',{staticClass:"acpt-alternative-text default--text text-decoration-underline text-none"},[_vm._v(" "+_vm._s(_vm.$t("cart.alternatives.showProposedAlternatives"))+" ")])]):_vm._e()],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('div',{staticClass:"vuedl-layout__closeBtn",on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" × ")]),_c('v-card',[_c('v-card-text',{staticClass:"body-1 pt-8"},[_vm._v(" "+_vm._s(_vm.$t("message.remove", { name: this.product.name, quantity: this.quantity, unit: this.unit }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.preventDefault();return _vm.doRemove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" Si ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }